import { LightgalleryProvider } from "react-lightgallery";
import PortfolioFilter from "../../../components/portfolio/portfolio-filter";
import PortfolioItem from "../../../components/portfolio/portfolio-item";
import React, { useEffect, useState } from "react";
import useMasonry from "../../../hooks/use-masonry";
import axios from "axios";

const PortfolioContainer = () => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredImages, setFilteredImages] = useState([]);
    const specificIds = [1, 6, 9, 8, 7, 13, 14, 15, 16];

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(
                    "https://effervescent-elf-f76296.netlify.app/.netlify/functions/api/"
                );
                const images = response.data;
                setImages(images);
                const filtered = images.filter((image) =>
                    specificIds.includes(image.id)
                );
                setFilteredImages(filtered);
            } catch (error) {
                console.error("Error fetching images:", error);
            } finally {
                setIsLoading(false); // Ensure loading state is updated
            }
        };

        fetchImages();
    }, [specificIds]);

    const { categories } = useMasonry(
        filteredImages,
        ".portfolio-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button button"
    );
    const handleLoadMore = () => {
        setIsLoading(false);
        setFilteredImages(images);
    };
    return (
        <div className="portfolio-area portfolio-default-area">
            <div className="container-fluid">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="row">
                        <div className="col-12">
                            <div className="messonry-button text-center mb-50">
                                <PortfolioFilter categories={categories} />
                            </div>
                        </div>
                    </div>
                )}
                ,
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                    <div className="col resizer"></div>

                    <LightgalleryProvider>
                        {filteredImages &&
                            filteredImages.map((portfolio) => (
                                <div
                                    key={portfolio.id}
                                    className={`col masonry-grid mb-30 ${portfolio.category}`}
                                >
                                    <PortfolioItem portfolio={portfolio} />
                                </div>
                            ))}
                    </LightgalleryProvider>
                </div>
                {isLoading ? (
                    <div className="row">
                        <div className="col-lg-12 text-center mt-60">
                            <button
                                className="btn-portfolio"
                                onClick={handleLoadMore}
                            >
                                Load More
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PortfolioContainer;
