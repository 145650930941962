import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
const PortfolioDetailsContainer = ({ data }) => {
    // const handleClick = (detail) => {
    //     // ImageDetailsContainer(detail)
    //     redirect(`/imageDetail`);

    //     console.log("Portfolio Image Clicked");
    // };
    console.log(data,'portfolio details ');
    const renderPortfolioItems = () => {
        let portfolioItems = [];
        // console.log(data,'if mela ');
        for (let i = 0; i < data.length; i += 5) {
            console.log(i, data.length);
                  portfolioItems.push(
                    <div key={i}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mt-3 inner-content">
                                    {data[i] && (  <div
                                            className="thumb section-padding-bottom"
                                            id="image1"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/image-details/${i+1}`,
                                                    state:{data:data[i]}
                                                }}
                                            >
                                                <img
                                                    className="w-100"
                                                    style={{
                                                        height: "787px",
                                                        width: "1169px",
                                                    }}
                                                    src={data[i].img}
                                                    alt={`PortfolioImage${i}`}
                                                />
                                            </Link>
                                        </div>)}

                                        {data[i+1] && (   <div className="row">
                                            <div
                                                className="col-lg-8 m-auto"
                                                data-aos="fade-up"
                                                data-aos-delay="300"
                                            >
                                                <div className="thumb section-padding-bottom">
                                                <Link
                                                to={{
                                                    pathname: `/image-details/${i+2}`,
                                                    state:{data:data[i+1]}
                                                }}
                                            >
                                                    <img
                                                        className="w-100"
                                                        style={{
                                                            height: "527px",
                                                            width: "772px",
                                                        }}
                                                        src={data[i + 1].img}
                                                        alt="Portfolio Image2"
                                                    />
                                                      </Link>
                                                </div>
                                            </div>
                                        </div>)}

                                        <div className="container-fluid p-0">
                                            <div className="row">
                                            {data[i+2] && (   <div
                                                    className="col-lg-12"
                                                    data-aos="fade-up"
                                                >
                                                    <div className="thumb section-padding-bottom">
                                                    <Link
                                                to={{
                                                    pathname: `/image-details/${i+3}`,
                                                    state:{data:data[i+2]}
                                                }}
                                            >
                                                        <img
                                                            className="w-100"
                                                            style={{
                                                                height: "949px",
                                                                width: "1920px",
                                                            }}
                                                            src={
                                                                data[i + 2].img
                                                            }
                                                            alt="Portfolio Image3"
                                                        />
                                                          </Link>
                                                    </div>
                                                </div>)}
                                                <div className="row thumb style-two">
                                                {data[i+3] && (  <div
                                                        className="col-md-6 col-lg-5 pl-sm-15 pl-0"
                                                        data-aos="fade-up"
                                                        data-aos-delay="300"
                                                    >
                                                        <Link
                                                to={{
                                                    pathname: `/image-details/${i+4}`,
                                                    state:{data:data[i+3]}
                                                }}
                                            >
                                                        <img
                                                            className="w-100"
                                                            style={{
                                                                height: "675px",
                                                                width: "1047px",
                                                                objectFit:"cover"
                                                            }}
                                                            src={
                                                                data[i + 3].img
                                                            }
                                                            alt="Portfolio Image4"
                                                        />
                                                        </Link>
                                                    </div>)}
                                                    {data[i+4] && (  <div
                                                        className="col-md-6 col-lg-7 text-end pr-sm-15 pr-0"
                                                        data-aos="fade-up"
                                                        data-aos-delay="600"
                                                    >
                                                         <Link
                                                to={{
                                                    pathname: `/image-details/${i+5}`,
                                                    state:{data:data[i+4]}
                                                }}
                                            >
                                                        <img
                                                            className="w-100"
                                                            style={{
                                                                height: "581px",
                                                                width: "875px",
                                                            }}
                                                            src={
                                                                data[i + 4].img
                                                            }
                                                            alt="Portfolio Image5"
                                                        />
                                                        </Link>
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            
        }
        return portfolioItems;
    };

    return (
        <div className="portfolio-area portfolio-single">
            <div className="portfolio-info">
                <div className="content" data-aos="fade-up">
                    <h3 className="title">{data[0].category}</h3>
                </div>
            </div>
            {renderPortfolioItems()}

            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <div className="portfolio-navigation">
                            <div className="prev">
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <i className="arrow_carrot-left"></i>{" "}
                                    Previous
                                </Link>
                                {/* Sample Project */}
                            </div>
                            {/* <div className="next">
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    Next <i className="arrow_carrot-right"></i>
                                </Link>
                                Sample Project
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default PortfolioDetailsContainer;
