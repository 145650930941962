import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import BlogTag from "../../components/blog/blog-tag";
import Comment from "../../components/comment";
import { slugify } from "../../utils";

// SwiperCore.use([Pagination]);

const ImageDetailsContainer = ({ data }) => {
    // const cate = (category) => {
    //     return (
    //         <Link
    //             className="category"
    //             to={process.env.PUBLIC_URL + `/category/${slugify(cate)}`}

    //         >
    //             {cate}
    //         </Link>
    //     );
    // };
    return (
        <div className="blog-details-area">
            <div className="post-details-content">
               
                    <div className="post-media">
                        <img src={`${data.img}`} alt="Nostalgia" />
                    </div>
               
                    <div className="post-audio audio-details">
            <div className="embed-responsive">
              <div
               >
                <h2>{data.title}</h2>
              </div>
            </div>
          </div>
          <div className="bread-crumbs">
           
           <span>Category: {data.category}</span>
         </div>
           <div className="post-details-body">
               <p>{data.description}</p>
               </div>  
            </div>
          
        </div>
    );
};

ImageDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ImageDetailsContainer;
