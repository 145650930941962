import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PortfolioDetailsContainer from "../containers/portfolio-details";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import axios from "axios";

const PortfolioDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get("https://effervescent-elf-f76296.netlify.app/.netlify/functions/api/details");
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [id]);

  const projectId = parseInt(id, 10);
  const filteredData = images
    ? images.filter((project) => project.categoryId == projectId).sort((a, b) => a.id - b.id) 
    : [];
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Nostalgia || Portfolio Details" />
        <div className="wrapper home-default-wrapper">
          <Header classOption="hb-border" />
          <div className="main-content">
            {images ? (
              <PortfolioDetailsContainer data={filteredData} />
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

PortfolioDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  }).isRequired,
};

export default PortfolioDetails;
