import PropTypes from "prop-types";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import React, { useEffect, useState } from "react";
import ImageDetailsContainer from "../containers/image-details";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import axios from "axios"; // Make sure axios is imported
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from 'react-router-dom';


const ImageDetails = () => {
    const location = useLocation();
    const { data } = location.state;
    console.log(data,'prop');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Nostalgia || Image Details" />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <ImageDetailsContainer data={data} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};


export default ImageDetails;
