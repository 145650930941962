import Map from "../../../components/map";

const MapContainer = () => {
  return (
    <div className="contact-map-area" data-aos="fade-up">
      <Map />
    </div>
  );
};

export default MapContainer;
